import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Ref = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect to home page after the component mounts
    navigate('/');
  }, [navigate]);

  return (
    <div className="flex items-center justify-center h-screen text-white">
      <h1 className="text-2xl">Unavailable at this moment! Redirecting you back...</h1>
    </div>
  );
};

export default Ref;
