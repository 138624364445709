import React from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { IoClose } from 'react-icons/io5';

function Header({ toggleSidebar, isSidebarOpen }) {
  return (
    <header className="fixed top-0 left-0 right-0 bg-[#0C0A09] text-white p-4 shadow z-10 border-b-2 border-[#1B1917] flex items-center justify-between">
      <img 
        src="https://mindhubai.app/logo.png" // Replace with the actual URL of your icon
        alt="App Icon" 
        className="h-10" // Adjusted size
      />
      <button onClick={toggleSidebar} className="ml-4 md:hidden">
        {isSidebarOpen ? (
          <IoClose className="w-6 h-6" />
        ) : (
          <GiHamburgerMenu className="w-6 h-6" />
        )}
      </button>
    </header>
  );
}

export default Header;
