import React, { useEffect, useState } from 'react';
import { FaTelegram, FaTwitter, FaDiscord, FaHome, FaCloud, FaRocket, FaTh, FaFileAlt, FaWallet, FaQuestionCircle, FaUser, FaGift } from 'react-icons/fa';
import { SiMedium } from 'react-icons/si';
import { GrBook } from 'react-icons/gr';
import { Link } from 'react-router-dom';
import { ethers } from 'ethers';

function Sidebar({ isOpen }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDisconnectModalOpen, setIsDisconnectModalOpen] = useState(false);
  const [walletAddress, setWalletAddress] = useState('');
  const [isConnected, setIsConnected] = useState(false);

  useEffect(() => {
    const savedAddress = localStorage.getItem('walletAddress');
    if (savedAddress) {
      setWalletAddress(savedAddress);
      setIsConnected(true);
    }
  }, []);

  const handleConnectWallet = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const connectWithMetaMask = async () => {
    if (window.ethereum) {
      try {
        const provider = new ethers.BrowserProvider(window.ethereum);
        await provider.send("eth_requestAccounts", []);
        const signer = await provider.getSigner();
        const address = await signer.getAddress();

        setWalletAddress(address);
        setIsConnected(true);
        localStorage.setItem('walletAddress', address); // Save to localStorage

        console.log("Connected with MetaMask:", address);
        closeModal();
      } catch (error) {
        console.error("Failed to connect with MetaMask:", error);
      }
    } else {
      console.log("MetaMask is not installed.");
    }
  };

  const disconnectWallet = () => {
    setIsDisconnectModalOpen(true);
  };

  const confirmDisconnect = () => {
    localStorage.removeItem('walletAddress');
    setWalletAddress('');
    setIsConnected(false);
    setIsDisconnectModalOpen(false);
  };

  const closeDisconnectModal = () => {
    setIsDisconnectModalOpen(false);
  };

  const shortenAddress = (address) => {
    return `${address.slice(0, 6)}...${address.slice(-4)}`;
  };

  return (
    <>
      <aside className={`flex flex-col fixed top-16 left-0 bottom-0 w-64 bg-black text-white p-4 shadow-lg border-r-2 border-[#1B1917] transition-transform duration-300 transform ${isOpen ? 'translate-x-0' : '-translate-x-full'} md:translate-x-0 z-50`}>
        <button className="flex items-center justify-center bg-[#459dd1] text-white py-2 px-4 rounded-lg shadow hover:bg-[#459dd1] transition duration-300 mb-4 text-sm">
          <FaRocket className="mr-2" />
          Create Ai Character
        </button>

        <div className="flex flex-col overflow-y-auto overflow-x-hidden h-full text-sm">
          <h2 className="text-base font-semibold mb-4 mt-4">Home</h2>

          <Link to="/" className="flex items-center justify-start text-white py-2 px-4 rounded-lg shadow transition duration-300 mb-2 hover:scale-105 hover:shadow-lg hover:border hover:border-white text-sm">
            <FaHome className="mr-2" />
            Home
          </Link>
          
          {/* Conditional rendering of buttons based on wallet connection */}
          <button className={`flex items-center justify-start text-white py-2 px-4 rounded-lg shadow transition duration-300 mb-2 ${isConnected ? 'hover:scale-105 hover:shadow-lg hover:border hover:border-white' : 'cursor-not-allowed opacity-50'}`} disabled={!isConnected}>
            <FaCloud className="mr-2" />
            Cloud Deployment
          </button>

          <Link to="/AllAi" className={`flex items-center justify-between text-white py-2 px-4 rounded-lg shadow transition duration-300 mb-2 ${isConnected ? 'hover:scale-105 hover:shadow-lg hover:border hover:border-white bg-gradient-to-r from-cyan-500 to-purple-600' : 'cursor-not-allowed opacity-50'}`}>
            <div className="flex items-center">
              <FaTh className="mr-2" />
              Ai Tools
            </div>
            <span className="ml-3 bg-teal-500 text-white text-xs font-semibold px-2 py-1 rounded-full shadow-lg">New</span>
          </Link>

          <Link to="/Portfolio" className={`flex items-center justify-between text-white py-2 px-4 rounded-lg shadow transition duration-300 mb-2 ${isConnected ? 'hover:scale-105 hover:shadow-lg hover:border hover:border-white' : 'cursor-not-allowed opacity-50'}`}>
            <div className="flex items-center">
              <FaWallet className="mr-2" />
              Portfolio Checker
            </div>
            <span className="ml-3 bg-teal-500 text-white text-xs font-semibold px-2 py-1 rounded-full shadow-lg">New</span>
          </Link>

          <button className={`flex items-center justify-start text-white py-2 px-4 rounded-lg shadow transition duration-300 mb-2 ${isConnected ? 'hover:scale-105 hover:shadow-lg hover:border hover:border-white' : 'cursor-not-allowed opacity-50'}`} disabled={!isConnected}>
            <FaFileAlt className="mr-2" />
            File
          </button>

          <div className="border-b border-gray-600 my-4" />

          <h2 className="text-base font-semibold mb-4 mt-4">Mindhub Ai</h2>
          <button className="flex items-center justify-start text-white py-2 px-4 rounded-lg shadow transition duration-300 mb-2 hover:scale-105 hover:shadow-lg hover:border hover:border-white text-sm">
            <FaGift className="mr-2" />
            Affiliates
          </button>
          <button className="flex items-center justify-start text-white py-2 px-4 rounded-lg shadow transition duration-300 mb-2 hover:scale-105 hover:shadow-lg hover:border hover:border-white text-sm">
            <FaUser className="mr-2" />
            Team
          </button>
          <button className="flex items-center justify-start text-white py-2 px-4 rounded-lg shadow transition duration-300 mb-2 hover:scale-105 hover:shadow-lg hover:border hover:border-white text-sm">
            <FaQuestionCircle className="mr-2" />
            Help
          </button>
        </div>

        {isConnected ? (
          <button
            className="flex items-center justify-center bg-[#459dd1] text-white py-2 px-4 rounded-lg shadow hover:bg-[#459dd1] transition duration-300 mb-4 text-sm"
            onClick={disconnectWallet}
          >
            <FaWallet className="mr-2" />
            {shortenAddress(walletAddress)}
          </button>
        ) : (
          <button className="flex items-center justify-center bg-[#459dd1] text-white py-2 px-4 rounded-lg shadow hover:bg-[#459dd1] transition duration-300 mb-4 text-sm" onClick={handleConnectWallet}>
            <FaWallet className="mr-2" />
            Connect Wallet
          </button>
        )}

        <div className="flex justify-center space-x-4 mt-4">
          <a href="https://t.me/MindHubAiPortal" target="_blank" rel="noopener noreferrer" className="text-white hover:text-blue-400">
            <FaTelegram className="w-6 h-6" />
          </a>
          <a href="https://twitter.com/mindhubai" target="_blank" rel="noopener noreferrer" className="text-white hover:text-blue-400">
            <FaTwitter className="w-6 h-6" />
          </a>
          <a href="https://discord.com/" target="_blank" rel="noopener noreferrer" className="text-white hover:text-blue-400">
            <FaDiscord className="w-6 h-6" />
          </a>
          <a href="#" target="_blank" rel="noopener noreferrer" className="text-white hover:text-blue-400">
            <SiMedium className="w-6 h-6" />
          </a>
          <a href="https://mindhubai.gitbook.io" target="_blank" rel="noopener noreferrer" className="text-white hover:text-blue-400">
            <GrBook className="w-6 h-6" />
          </a>
        </div>

        <div className="flex items-center justify-center mt-4 text-sm">
          <div className="text-yellow-400 mr-2">v2.121</div>
          <div className="border border-white text-center text-yellow-400 py-0.5 px-2 rounded">(Beta)</div>
        </div>
      </aside>

      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 shadow-lg">
            <h2 className="text-lg font-semibold mb-4">Connect Wallet</h2>
            <button
              className="bg-blue-500 text-white py-2 px-4 rounded"
              onClick={connectWithMetaMask}
            >
              Connect with MetaMask
            </button>
            <button className="mt-4 text-red-500" onClick={closeModal}>Close</button>
          </div>
        </div>
      )}

      {isDisconnectModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 shadow-lg">
            <h2 className="text-lg font-semibold mb-4">Disconnect Wallet</h2>
            <p>Are you sure you want to disconnect your wallet?</p>
            <button className="bg-red-500 text-white py-2 px-4 rounded mt-4" onClick={confirmDisconnect}>Yes, Disconnect</button>
            <button className="mt-4 text-red-500" onClick={closeDisconnectModal}>Cancel</button>
          </div>
        </div>
      )}
    </>
  );
}

export default Sidebar;
