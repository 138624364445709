import React, { useState, useEffect } from 'react';
import { FaEdit } from 'react-icons/fa';
import 'prismjs/themes/prism.css';
import Prism from 'prismjs';

function CodeDisplay({ response, language }) {
  useEffect(() => {
    // Highlight the code after rendering
    Prism.highlightAll();
  }, [response]);

  return (
    <div className="mb-4">
      <label className="block text-sm font-medium text-gray-300 mb-1">Code Response</label>
      <div className="w-full h-52 p-4 border border-gray-600 text-gray-300 rounded-md overflow-auto">
        <pre className="bg-transparent">
          <code className={`language-${language}`}> {/* Use the selected language */}
            {response}
          </code>
        </pre>
      </div>
    </div>
  );
}

function Contents() {
  const [title, setTitle] = useState('Untitled');
  const [isEditing, setIsEditing] = useState(false);
  const [newTitle, setNewTitle] = useState(title);
  const [description, setDescription] = useState('');
  const [language, setLanguage] = useState('');
  const [response, setResponse] = useState('');
  const [loading, setLoading] = useState(false);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleTitleChange = (e) => {
    setNewTitle(e.target.value);
  };

  const handleTitleSubmit = (e) => {
    e.preventDefault();
    setTitle(newTitle);
    setIsEditing(false);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setResponse(''); // Clear previous response
    setLoading(true); // Show loader

    const prompt = `Write me a ${description} using programming language of ${language} do it professionally and no explanation needed just the Script.`;

    try {
      const res = await fetch('https://mindhubai.xyz:4000/generate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ message: prompt }), // Send the prompt in the "message" field
      });

      const data = await res.json();

      if (data && data.response) {
        setResponse(data.response);
      } else {
        setResponse('No response from the AI.');
      }
    } catch (error) {
      console.error('Error data:', error);
      setResponse('Error data.');
    } finally {
      setLoading(false); // Hide loader
    }
  };

  return (
    <main
      className="flex-1 text-white p-4 border-l-2 border-[#1B1917] md:pl-64"
      style={{
        minHeight: '100vh',
        backgroundColor: '#000000',
        backgroundImage: `
          radial-gradient(circle at 20% 30%, rgba(0, 255, 255, 0.3) 0%, transparent 50%),
          radial-gradient(circle at 80% 70%, rgba(0, 0, 0, 0.95) 0%, transparent 50%),
          radial-gradient(circle at 50% 50%, rgba(128, 0, 128, 0.3) 0%, transparent 50%)
        `,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
      }}
    >
      <div className="flex-1 flex flex-col">
        <header className="text-center mt-20 px-4">
          <h1 className="text-2xl font-bold">MindCoder</h1>
          <p className="text-sm mt-1">
          MindCoder: Unleashing Your Coding Potential
          </p>
        </header>

        {/* Container with two aligned sections */}
        <div className="container mx-auto mt-8 flex flex-col md:flex-row gap-6">
          {/* First Section */}
          <div className="flex-1 p-6 border border-gray-700 rounded-lg">
            <h2 className="text-xl font-semibold mb-4">Recent</h2>

            {/* Form for Article Title and Settings */}
            <form onSubmit={handleFormSubmit} className="space-y-4">
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-300 mb-1">Description</label>
                <textarea
                  type="text"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  className="w-full p-2 border border-gray-600 bg-transparent text-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-cyan-500"
                  placeholder="Enter Description"
                />
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-300 mb-1">Language</label>
                <input
                  type="text"
                  value={language}
                  onChange={(e) => setLanguage(e.target.value)}
                  className="w-full p-2 border border-gray-600 bg-transparent text-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-cyan-500"
                  placeholder="example Html,Js,Python,Php"
                />
              </div>

              {/* Gradient Button */}
              <button
                type="submit"
                className="w-full py-2 px-4 rounded-lg text-white font-semibold bg-gradient-to-r from-cyan-500 to-purple-500 hover:from-cyan-600 hover:to-purple-600 transition duration-300"
              >
                Generate
              </button>
            </form>
          </div>

          {/* Second Section styled like a textarea */}
          <div className="flex-1 p-6 border border-gray-700 rounded-lg bg-transparent text-gray-300">
            <div className="flex items-center mb-4">
              {isEditing ? (
                <form onSubmit={handleTitleSubmit} className="flex items-center w-full">
                  <input
                    type="text"
                    value={newTitle}
                    onChange={handleTitleChange}
                    className="w-full p-2 border border-gray-600 bg-transparent text-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-cyan-500"
                    autoFocus
                  />
                  <button type="submit" className="ml-2 text-cyan-500">Save</button>
                </form>
              ) : (
                <>
                  <h2 className="text-xl font-semibold flex-1">{title}</h2>
                  <FaEdit 
                    className="ml-2 cursor-pointer text-gray-400 hover:text-gray-300"
                    onClick={handleEditClick}
                  />
                </>
              )}
            </div>
            {/* Code Display */}
            {loading ? (
              <div className="flex justify-center items-center h-full">
                <span className="text-gray-400">Loading...</span>
              </div>
            ) : (
              <CodeDisplay response={response} language={language} />
            )}
          </div>
        </div>
      </div>
    </main>
  );
}

export default Contents;
