import React, { useState, useEffect } from 'react';
import { FaBolt, FaTh, FaLock } from 'react-icons/fa';
import { AiFillEdit, AiFillMessage, AiFillPicture, AiFillCode, AiFillSound } from 'react-icons/ai';
import { Link } from 'react-router-dom';

function Content() {
  const [isWalletConnected, setIsWalletConnected] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [account, setAccount] = useState('');

  useEffect(() => {
    const checkWalletConnection = async () => {
      if (window.ethereum) {
        const accounts = await window.ethereum.request({ method: 'eth_accounts' });
        setIsWalletConnected(accounts.length > 0);
        if (accounts.length > 0) {
          setAccount(accounts[0]); // Set the connected account
        } else {
          setIsModalOpen(true); // Open modal if no wallet connected
        }
      } else {
        setIsModalOpen(true); // Show modal if no Ethereum provider is available
      }
    };

    checkWalletConnection();
  }, []);

  const connectWallet = async () => {
    try {
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      setIsWalletConnected(true);
      setIsModalOpen(false);
      setAccount(accounts[0]); // Set the connected account
    } catch (error) {
      console.error('Connection error:', error);
    }
  };

  // Function to shorten the Ethereum address
  const shortenAddress = (address) => {
    if (!address) return '';
    return `${address.slice(0, 6)}...${address.slice(-4)}`;
  };

  return (
    <main
      className="flex-1 text-white pt-20 p-4 border-l-2 border-[#1B1917] md:pl-64"
      style={{
        background: `
          radial-gradient(at 20% 30%, rgba(0, 255, 255, 0.3) 0px, transparent 50%),
          radial-gradient(at 80% 70%, rgba(0, 128, 128, 0.2) 0px, transparent 50%),
          radial-gradient(at 50% 50%, rgba(0, 0, 0, 0.95) 0px, transparent 50%)
        `,
        minHeight: '100vh', // Ensures it takes full viewport height
        height: '100vh', // Optionally enforce full height
      }}
    >
      {/* Blur overlay if wallet is not connected */}
      {!isWalletConnected && (
        <div className="absolute inset-0 bg-black bg-opacity-50 backdrop-blur-[10px] z-10" />
      )}

      {/* Modal for connecting wallet */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-20">
          <div
            className="rounded-lg p-6 shadow-lg border border-white/30 w-80"
            style={{
              background: 'rgba(255, 255, 255, 0.2)', // Semi-transparent background
              borderRadius: '16px', // Rounded corners
              boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)', // Shadow effect
              backdropFilter: 'blur(5px)', // Blurring the background
              WebkitBackdropFilter: 'blur(5px)', // For Safari
            }}
          >
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/36/MetaMask_Fox.svg/512px-MetaMask_Fox.svg.png?20220831120339"
              alt="MetaMask Logo"
              className="w-24 h-24 mx-auto mb-4"
            />
            <h2 className="text-lg font-semibold text-center text-white mb-4">Connect With MetaMask</h2>
            <button
              onClick={connectWallet}
              className="w-full h-10 bg-black text-white rounded-lg shadow-md hover:bg-gray-800 transition duration-300"
            >
              Connect
            </button>
          </div>
        </div>
      )}

      <div className="container mx-auto flex flex-col md:flex-row gap-4">
        <div className="flex-1 p-4 mt-4 max-w-4xl">
          <div className="mb-2">
            <h3 className="text-sm text-gray-400">Dashboard</h3>
            <h2 className="text-xl font-semibold">Welcome {shortenAddress(account)}</h2> {/* Display shortened address */}
          </div>
          <p>
            View your account status, billing details, and other important
            information here. Quickly access your account activities and manage
            your settings.
          </p>

          <div className="flex flex-col items-center gap-4 mt-6">
            <div className="flex justify-center items-center gap-4">
              <div
                className="w-32 h-32 border border-white/30 rounded-full 
                           bg-transparent shadow-[0_4px_10px_1px_rgba(255,255,255,0.3)] transition-shadow duration-300 flex items-center justify-center"
              >
                <div className="text-center">
                  <span className="text-transparent bg-clip-text bg-gradient-to-r from-cyan-400 to-purple-600 font-bold text-lg">
                    0%
                  </span>
                  <br />
                  <span className="text-transparent bg-clip-text bg-gradient-to-r from-cyan-400 to-purple-600 font-bold text-sm">
                    Credit Usage
                  </span>
                </div>
              </div>

              <div className="flex flex-col gap-4">
                <div
                  className="w-48 h-16 border border-white/30 rounded-lg bg-black bg-opacity-30 shadow-[0_4px_10px_1px_rgba(255,255,255,0.3)] transition-shadow duration-300 flex items-center justify-center"
                >
                  <span className="text-white text-xs font-bold text-center">Remaining Words : ∞</span>
                </div>

                <div
                  className="w-48 h-16 border border-white/30 rounded-lg bg-black bg-opacity-30 shadow-[0_4px_10px_1px rgba(255,255,255,0.3)] transition-shadow duration-300 flex items-center justify-center"
                >
                  <span className="text-white text-xs font-bold text-center">Remaining Images : ∞</span>
                </div>
              </div>
            </div>

            <div
              className="w-full max-w-xl h-12 border border-white/30 rounded-lg 
                         bg-gradient-to-r from-purple-500 to-cyan-500 shadow-[0_4px_10px_1px_rgba(255,255,255,0.3)] 
                         transition-shadow duration-300 flex items-center justify-center mt-4 cursor-pointer 
                         hover:opacity-80"
            >
              <span className="flex items-center text-white text-sm font-bold">
                <FaBolt className="mr-2" />
                Upgrade
              </span>
            </div>
          </div>
        </div>

        <div className="flex-1 p-4 mt-4 w-full max-w-3xl rounded-lg">
          <div className="flex space-x-2 mb-4">
            <span className="border border-white rounded-full px-2 text-xs text-white">Featured</span>
            <span className="border border-white rounded-full px-2 text-xs text-white">AI</span>
            <span className="border border-white rounded-full px-2 text-xs text-white">Free Tools</span>
          </div>

          <h2 className="text-2xl font-semibold mb-4">Quick Access</h2>
          <p className="mb-6">
            You have no subscription at the moment.
            But Hey! It's Beta Access, which means some tools are free to use!
          </p>

          <div className="grid grid-cols-2 gap-6 md:grid-cols-2 lg:grid-cols-3">
            {[
              { href: "#", icon: <AiFillEdit size={24} />, text: "Editor" },
              { href: "/ai/chat", icon: <AiFillMessage size={24} />, text: "Chat" },
              { href: "/ai/image-gen", icon: <AiFillPicture size={24} />, text: "Image" },
              { href: "/ai/code", icon: <AiFillCode size={24} />, text: "Code" },
              { href: "/ai/Tts", icon: <AiFillSound size={24} />, text: "Text To Voice" },
              { href: "/AllAi", icon: <FaTh size={24} />, text: "All Free Tools" }
            ].map((item, index) => (
              <Link to={item.href} key={index}>
                <div className="p-4 border border-white/30 rounded-lg bg-black bg-opacity-30 transition-all duration-200 hover:shadow-lg">
                  <div className="flex items-center">
                    {item.icon}
                    <span className="ml-2 text-white">{item.text}</span>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </main>
  );
}

export default Content;
