import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import Content from './components/Content';
import AllAi from './page/AllAi';
import Portfolio from './page/Portfolio';
import PostTitleGenerator from './ai/PostTitleGenerator';
import SummarizeText from './ai/SummarizeText';
import ProductDescription from './ai/ProductDescription';
import ArticleGenerator from './ai/ArticleGenerator';
import ProductNameGenerator from './ai/ProductNameGenerator';
import TestimonialReview from './ai/TestimonialReview';
import ProblemAgitateSolution from './ai/ProblemAgitateSolution';
import BlogSection from './ai/BlogSection';
import BlogPostIdeas from './ai/BlogPostIdeas';
import BlogIntros from './ai/BlogIntros';
import BlogConclusion from './ai/BlogConclusion';
import FacebookAds from './ai/FacebookAds';
import InstagramHashtags from './ai/InstagramHashtags';
import SocialMediaPostTweets from './ai/SocialMediaPostTweets';
import YoutubeVideoDescription from './ai/YoutubeVideoDescription';
import YoutubeVideoTitle from './ai/YoutubeVideoTitle';
import YoutubeVideoTag from './ai/YoutubeVideoTag';
import InstagramCaptions from './ai/InstagramCaptions';
import SocialMediaPostBusiness from './ai/SocialMediaPostBusiness';
import FacebookHeadlines from './ai/FacebookHeadlines';
import GoogleAdsHeadlines from './ai/GoogleAdsHeadlines';
import GoogleAdsDescription from './ai/GoogleAdsDescription';
import ParagraphGenerator from './ai/ParagraphGenerator';
import ProsCons from './ai/ProsCons';
import MetaDescription from './ai/MetaDescription';
import FAQGenerator from './ai/FAQGenerator';
import EmailGenerator from './ai/EmailGenerator';
import EmailAnswerGenerator from './ai/EmailAnswerGenerator'; // Adjusted the path
import ImageGen from './ai/ImageGen'; // Adjusted the path
import TextToSpeech from './ai/TextToSpeech'; // Adjusted the path
import Codes from './ai/Code'; // Adjusted the path
import Chat from './ai/Chat'; // Adjusted the path

function App() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  return (
    <Router>
      <div className="flex flex-col min-h-screen"> {/* Ensure the entire screen height is used */}
        <Header toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} />
        <div className="flex flex-1 relative"> {/* Flex container that takes remaining height */}
          <Sidebar isOpen={isSidebarOpen} />
          <div className="flex-1 overflow-y-auto bg-black"> {/* Allow scrolling if content overflows */}
            <Routes>
              <Route path="/" element={<Content />} /> {/* Render Content at the root path */}
              <Route path="/AllAi" element={<AllAi />} /> {/* Render AllAi at /AllAi path */}
              <Route path="/Portfolio" element={<Portfolio />} /> {/* Render Portfolio at /Portfolio path */}
              <Route path="/ai/post-title-generator" element={<PostTitleGenerator />} />
              <Route path="/ai/summarize-text" element={<SummarizeText />} />
              <Route path="/ai/product-description" element={<ProductDescription />} />
              <Route path="/ai/article-generator" element={<ArticleGenerator />} />
              <Route path="/ai/product-name-generator" element={<ProductNameGenerator />} />
              <Route path="/ai/testimonial-review" element={<TestimonialReview />} />
              <Route path="/ai/problem-agitate-solution" element={<ProblemAgitateSolution />} />
              <Route path="/ai/blog-section" element={<BlogSection />} />
              <Route path="/ai/blog-post-ideas" element={<BlogPostIdeas />} />
              <Route path="/ai/blog-intros" element={<BlogIntros />} />
              <Route path="/ai/blog-conclusion" element={<BlogConclusion />} />
              <Route path="/ai/facebook-ads" element={<FacebookAds />} />
              <Route path="/ai/instagram-hashtags" element={<InstagramHashtags />} />
              <Route path="/ai/social-media-post-tweets" element={<SocialMediaPostTweets />} />
              <Route path="/ai/youtube-video-description" element={<YoutubeVideoDescription />} />
              <Route path="/ai/youtube-video-title" element={<YoutubeVideoTitle />} />
              <Route path="/ai/youtube-video-tag" element={<YoutubeVideoTag />} />
              <Route path="/ai/instagram-captions" element={<InstagramCaptions />} />
              <Route path="/ai/social-media-post-business" element={<SocialMediaPostBusiness />} />
              <Route path="/ai/facebook-headlines" element={<FacebookHeadlines />} />
              <Route path="/ai/google-ads-headlines" element={<GoogleAdsHeadlines />} />
              <Route path="/ai/google-ads-description" element={<GoogleAdsDescription />} />
              <Route path="/ai/paragraph-generator" element={<ParagraphGenerator />} />
              <Route path="/ai/pros-cons" element={<ProsCons />} />
              <Route path="/ai/meta-description" element={<MetaDescription />} />
              <Route path="/ai/faq-generator" element={<FAQGenerator />} />
              <Route path="/ai/email-generator" element={<EmailGenerator />} />
              <Route path="/ai/email-answer-generator" element={<EmailAnswerGenerator />} />
              <Route path="/ai/image-gen" element={<ImageGen />} />
              <Route path="/ai/tts" element={<TextToSpeech />} />
              <Route path="/ai/code" element={<Codes />} />
              <Route path="/ai/chat" element={<Chat />} />

              {/* Define other routes here */}
            </Routes>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;
