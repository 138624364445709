import React, { useState } from 'react';
import { FaEdit } from 'react-icons/fa';

// CSS styles for the loader and audio player
const loaderStyle = `
  .loader {
    width: 50px;
    --b: 8px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: #514b82;
    -webkit-mask:
      repeating-conic-gradient(#0000 0deg, #000 1deg 70deg, #0000 71deg 90deg),
      radial-gradient(farthest-side, #0000 calc(100% - var(--b) - 1px), #000 calc(100% - var(--b)));
    -webkit-mask-composite: destination-in;
    mask-composite: intersect;
    animation: l5 1s infinite;
  }

  @keyframes l5 {
    to {
      transform: rotate(.5turn);
    }
  }

  .audio-player {
    border: 3px solid transparent;
    border-radius: 8px;
    background: linear-gradient(white, white),
                linear-gradient(to right, cyan, purple);
    background-clip: padding-box, border-box;
    background-origin: border-box;
  }

  .download-button {
    margin-top: 10px;
    padding: 8px 16px;
    border: none;
    background-color: cyan;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: block; /* Centering */
    margin-left: auto; /* Centering */
    margin-right: auto; /* Centering */
  }

  .download-button:hover {
    background-color: darkcyan;
  }
`;

function Contents() {
  const [title, setTitle] = useState('Untitled');
  const [isEditing, setIsEditing] = useState(false);
  const [newTitle, setNewTitle] = useState(title);
  const [description, setDescription] = useState(''); 
  const [keywords, setKeywords] = useState(''); 
  const [language, setLanguage] = useState(''); 
  const [character, setCharacter] = useState(''); 
  const [voiceStyle, setVoiceStyle] = useState(''); 
  const [response, setResponse] = useState(null); 
  const [loading, setLoading] = useState(false); 
  const [error, setError] = useState(''); // Error state
  const [remainingCharacters, setRemainingCharacters] = useState(200);


  const characterData = {
    English: [
      { name: "Natalie (F)", id: "VM016372341539042UZ" },
      { name: "Terrell (M)", id: "VM0164121392130292Q" },
    ],
    Spanish: [
      { name: "Carlos (M)", id: "VM0169899063056443C" },
      { name: "Lorena (F)", id: "VM017052943651315SN" },
    ],
    French: [
      { name: "Adélie (F)", id: "VM016928805078045TZ" },
      { name: "Maxime (M)", id: "VM01692256013822766" },
    ],
    German: [
      { name: "Matthias (M)", id: "VM01698990630618719" },
      { name: "Josephine (F)", id: "VM0169899063052611X" },
    ],
    Chinese: [
      { name: "Liu (F)", id: "V016019901870428BQ" },
      { name: "Hong (M)", id: "V016019901870451HA" },
    ],
    Russian: [
      { name: "Vladimir (M)", id: "V0160752226591396F" },
      { name: "Irina (F)", id: "V016019901870387GR" },
    ],
    Arabic: [
      { name: "Khalid (M)", id: "V016019901792998WY" },
      { name: "Samirah (F)", id: "V016019901870208NW" },
    ],
    "India (English)": [
      { name: "Aarav (M)", id: "VM016999642506773QU" },
      { name: "Isha (F)", id: "VM016902010890943DZ" },
    ],
    Japanese: [
      { name: "Reo (M)", id: "V016019901870354NU" },
      { name: "Sakura (F)", id: "V0160199018703556Z" },
    ],
    Korean: [
      { name: "Hyun-jun (M)", id: "V016019901870356QQ" },
      { name: "Seo-yun (F)", id: "V016019901793325KG" },
    ],
    Italian: [
      { name: "Giorgio (M)", id: "VM0171344018607752H" },
      { name: "Vera (F)", id: "VM017071193653525CP" },
    ],
    Turkish: [
      { name: "Azra (F)", id: "V016019901870426RA" },
      { name: "Hamza (M)", id: "V016075222659166UM" },
    ],
    Indonesian: [
      { name: "Adhiarja (M)", id: "V016019901870329SZ" },
      { name: "Indah (F)", id: "V016075222659083CG" },
    ],
    Romanian: [
      { name: "Cristina (F)", id: "V016019901870386UI" },
      { name: "Adrian (M)", id: "V016019901870137AE" },
    ],
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleTitleChange = (e) => {
    setNewTitle(e.target.value);
  };

  const handleTitleSubmit = (e) => {
    e.preventDefault();
    setTitle(newTitle);
    setIsEditing(false);
  };

  const fetchAudio = async (keywords, character, voiceStyle) => {
    try {
      const response = await fetch(`https://mindhubai.xyz:4000/tts?keywords=${encodeURIComponent(keywords)}&character=${character}&voiceStyle=${voiceStyle}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const audioBlob = await response.blob();
      const audioUrl = URL.createObjectURL(audioBlob);
      setResponse({ audioUrl }); 
    } catch (error) {
      console.error('Error fetching audio:', error);
      setResponse(null); 
    } finally {
      setLoading(false); 
    }
  };

  const handleKeywordsChange = (e) => {
    const value = e.target.value;
    setKeywords(value);
    setRemainingCharacters(200 - value.length); // Update remaining characters
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setError(''); // Reset error state

    // Check for empty fields and maximum character limit
    if (!keywords || !language || !character || !voiceStyle) {
      setError('Please fill in all fields.');
      return; // Prevent submission
    }

    if (keywords.length > 200) {
      setError('Keywords must be 200 characters or less.');
      return; // Prevent submission
    }

    setLoading(true);
    setResponse(null); // Clear previous response
    await fetchAudio(keywords, character, voiceStyle);
  };
  

  const characterOptions = language ? characterData[language] || [] : [];

  const downloadAudio = () => {
    if (response && response.audioUrl) {
      const timestamp = Math.floor(Date.now() / 1000); // Get Unix timestamp
      const link = document.createElement('a');
      link.href = response.audioUrl;
      link.setAttribute('download', `audio_${timestamp}.mp3`); // Set the filename with Unix timestamp
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Clean up
    }
  };

  return (
    <main
      className="flex-1 text-white p-4 border-l-2 border-[#1B1917] md:pl-64"
      style={{
        minHeight: '100vh',
        backgroundColor: '#000000',
        backgroundImage: `
          radial-gradient(circle at 20% 30%, rgba(0, 255, 255, 0.3) 0%, transparent 50%),
          radial-gradient(circle at 80% 70%, rgba(0, 0, 0, 0.95) 0%, transparent 50%),
          radial-gradient(circle at 50% 50%, rgba(128, 0, 128, 0.3) 0%, transparent 50%)
        `,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
      }}
    >
      <style>{loaderStyle}</style>

      <div className="flex-1 flex flex-col">
        <header className="text-center mt-20 px-4">
          <h1 className="text-2xl font-bold">MindGen Audio</h1>
          <p className="text-sm mt-1">MindGen: Crafting Descriptive Audios</p>
        </header>

        <div className="container mx-auto mt-8 flex flex-col md:flex-row gap-6">
          <div className="flex-1 p-6 border border-gray-700 rounded-lg">
            <h2 className="text-xl font-semibold mb-4">Recent</h2>

            {error && <p className="text-red-500">{error}</p>} {/* Display error message */}

            <form onSubmit={handleFormSubmit} className="space-y-4">
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-300 mb-1">
          Text To Speech Keywords (Max 200 Characters)
        </label>
        <textarea
          className="w-full h-24 p-2 rounded-md bg-transparent text-white border border-gray-700"
          value={keywords}
          onChange={handleKeywordsChange}
          placeholder="Enter keywords..."
          maxLength={200} // Restrict input length
        />
        <p className="text-sm text-gray-400 mt-1">
          {remainingCharacters} characters left
        </p>
      </div>

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-300 mb-1">Select Language</label>
                <select
                  className="w-full h-12 p-2 rounded-md bg-transparent text-white border border-gray-700"
                  value={language}
                  onChange={(e) => setLanguage(e.target.value)}
                >
                  <option value="">-- Select Language --</option>
                  {Object.keys(characterData).map((lang) => (
                    <option key={lang} value={lang}>{lang}</option>
                  ))}
                </select>
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-300 mb-1">Select Character</label>
                <select
                  className="w-full h-12 p-2 rounded-md bg-transparent text-white border border-gray-700"
                  value={character}
                  onChange={(e) => setCharacter(e.target.value)}
                  disabled={!language}
                >
                  <option value="">-- Select Character --</option>
                  {characterOptions.map((char) => (
                    <option key={char.id} value={char.id}>{char.name}</option>
                  ))}
                </select>
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-300 mb-1">Select Voice Style</label>
                <select
                  className="w-full h-12 p-2 rounded-md bg-transparent text-white border border-gray-700"
                  value={voiceStyle}
                  onChange={(e) => setVoiceStyle(e.target.value)}
                  disabled={!character}
                >
                  <option value="">Select Voice Style</option>
                  <option value="Promo">Promo</option>
                  <option value="Narration">Narration</option>
                  <option value="Newscast Formal">Newscast Formal</option>
                  <option value="Meditative">Meditative</option>
                  <option value="Sad">Sad</option>
                  <option value="Angry">Angry</option>
                  <option value="Conversational">Conversational</option>
                  <option value="Newscast Casual">Newscast Casual</option>
                  <option value="Furious">Furious</option>
                  <option value="Sorrowful">Sorrowful</option>
                  <option value="Terrified">Terrified</option>
                  <option value="Inspirational">Inspirational</option>
                </select>
              </div>

              <button type="submit" className="w-full h-12 bg-gradient-to-r from-cyan-500 to-purple-500 text-white rounded-md hover:opacity-80">
                Generate Audio
              </button>
            </form>
          </div>

          <div className="flex-1 p-6 border border-gray-700 rounded-lg">
  <h2 className="text-xl font-semibold mb-4">Result</h2>

  {loading && <div className="loader mx-auto"></div>} {/* Loader component */}
  {response && response.audioUrl && (
    <>
      <audio controls autoPlay className="audio-player w-full mt-4">
        <source src={response.audioUrl} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>

        <button onClick={downloadAudio} className="download-button mt-4">
        Download Audio
      </button>
    </>
  )}
</div>

        </div>
      </div>
    </main>
  );
}

export default Contents;