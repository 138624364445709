import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios'; // Import axios
import { FaUser, FaRobot } from 'react-icons/fa'; // Import React Icons

function Contents() {
  const [prompt, setNewprompt] = useState('');
  const [messages, setMessages] = useState([]); // Store messages as an array
  const [isRefreshing, setIsRefreshing] = useState(false); // Loading state for refreshing
  const messagesEndRef = useRef(null); // Ref for scrolling to the bottom

  // Trigger refresh on component mount
  useEffect(() => {
    const triggerRefresh = async () => {
      setIsRefreshing(true); // Set loading state
      try {
        const res = await axios.post('https://mindhubai.xyz:3000/refresh');
  
        if (res.status === 200) {
          console.log('Chat refreshed successfully');
        } else {
          console.error('Error refreshing. Please try again later.');
          setMessages((prevMessages) => [
            ...prevMessages,
            { type: 'bot', text: 'Error refreshing. Please try again later.' },
          ]);
        }
      } catch (error) {
        console.error('Error refreshing chat:', error);
        setMessages((prevMessages) => [
          ...prevMessages,
          { type: 'bot', text: `Error: ${error.message}` },
        ]);
      } finally {
        setIsRefreshing(false); // Reset loading state after fetch completes
      }
    };
  
    triggerRefresh();
  }, []); // Empty dependency array to run only on mount
  

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const userMessage = prompt.trim();

    if (!userMessage) return; // Prevent sending empty messages

    // Add user message to the messages array
    setMessages((prevMessages) => [
      ...prevMessages,
      { type: 'user', text: userMessage },
    ]);

    try {
      const res = await axios.post('https://mindhubai.xyz:3000/mindhub', {
        message: userMessage, // Send the prompt in the "message" field
      });

      const data = res.data;

      // Add bot response to the messages array after user message
      if (data && data.response) {
        setMessages((prevMessages) => [
          ...prevMessages,
          { type: 'bot', text: data.response },
        ]);
      } else {
        // Handle no response case
        setMessages((prevMessages) => [
          ...prevMessages,
          { type: 'bot', text: 'No response from the AI.' },
        ]);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setMessages((prevMessages) => [
        ...prevMessages,
        { type: 'bot', text: 'Error fetching data.' },
      ]);
    }

    // Clear the prompt input
    setNewprompt('');
  };

  // Scroll to the bottom of the chat on new messages
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  return (
    <main
      className="flex-1 text-white p-4 border-l-2 border-[#1B1917] md:pl-64"
      style={{
        minHeight: '100vh',
        backgroundColor: '#000000',
        backgroundImage: `
          radial-gradient(circle at 20% 30%, rgba(0, 255, 255, 0.3) 0%, transparent 50%),
          radial-gradient(circle at 80% 70%, rgba(0, 0, 0, 0.95) 0%, transparent 50%),
          radial-gradient(circle at 50% 50%, rgba(128, 0, 128, 0.3) 0%, transparent 50%)
        `,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <div className="flex-1 flex flex-col">
        <header className="text-center mt-20 px-4">
          <h1 className="text-2xl font-bold">MindHub Ai: Aeon</h1>
          <p className="text-sm mt-1">Your Super Useful Assistant Ai: AEON</p>
        </header>

        {/* Chat Container */}
        <div className="container mx-auto flex flex-col">
          {/* Fixed Size Chatbox */}
          <div className="h-[80vh] max-h-[80vh] w-full border border-gray-700 rounded-lg p-4 flex flex-col">
            {/* Chat Messages Container */}
            <div
              className="flex-1 overflow-auto mb-4 p-4 rounded-lg"
              style={{
                backgroundColor: 'rgba(255, 255, 255, 0.1)', // Slight transparency for better visibility
                display: 'flex',
                flexDirection: 'column', // Change back to column
              }}
            >
              {messages.length === 0 ? (
                <p className="text-gray-400">Start a conversation...</p>
              ) : (
                messages.map((message, index) => (
                  <div key={index} className="flex items-start my-2">
                    {message.type === 'bot' && (
                      <FaRobot className="text-white w-5 h-5 mr-2 self-center" /> // Smaller bot icon
                    )}
                    <div
                      className={`p-3 rounded-lg text-white break-words ${
                        message.type === 'user'
                          ? 'bg-cyan-500 self-end ml-auto' // User chat bubble on the right
                          : 'bg-purple-500 self-start mr-auto' // Bot chat bubble on the left
                      }`}
                      style={{
                        maxWidth: '70%', // Limit the bubble width for better visibility
                        wordWrap: 'break-word', // Ensure long words break appropriately
                        display: 'inline-block', // Make bubbles expand based on content
                      }}
                    >
                      {message.text}
                    </div>
                    {message.type === 'user' && (
                      <FaUser className="text-white w-5 h-5 ml-2 self-center" /> // Smaller user icon
                    )}
                  </div>
                ))
              )}
              {/* Add a div at the end of the messages for scrolling */}
              <div ref={messagesEndRef} />
            </div>

            {/* Form for Prompt Input */}
            <form onSubmit={handleFormSubmit} className="flex items-center space-x-2">
              <textarea
                value={prompt}
                onChange={(e) => setNewprompt(e.target.value)}
                className="w-full p-2 border border-gray-600 bg-transparent text-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-cyan-500"
                placeholder="Type your message here..."
                rows={2}
                style={{ resize: 'none' }} // Prevent resizing the textarea
              />
              <button
                type="submit"
                disabled={isRefreshing} // Disable button during refresh
                className="py-2 px-4 rounded-lg text-white font-semibold bg-gradient-to-r from-cyan-500 to-purple-500 hover:from-cyan-600 hover:to-purple-600 transition duration-300"
              >
                Send
              </button>
            </form>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Contents;
