import React from 'react';
import { FaStar, FaTag, FaParagraph, FaBox, FaNewspaper, FaCogs, FaExclamationTriangle, FaStream, FaLightbulb, FaPenNib, FaCheckCircle, FaFacebookF, FaHashtag, FaTwitter, FaVideo, FaInstagram, FaBriefcase, FaGoogle, FaBalanceScale, FaQuestionCircle, FaEnvelope, FaSearch } from 'react-icons/fa'; // Import different icons
import { Link } from 'react-router-dom';

function AllAi() {
  // Define the button data with unique titles, descriptions, and icons
  const buttonData = [
    { title: 'Post Title Generator', description: 'Craft compelling and click-worthy titles for your posts with our Post Title Generator. Perfect for bloggers, content creators, and marketers looking to capture attention and drive engagement.', icon: <FaTag />, link: '/ai/post-title-generator' },
    { title: 'Summarize Text', description: 'Condense lengthy articles, reports, or documents into concise summaries with our Summarize Text tool. Ideal for quick overviews or extracting key information efficiently.', icon: <FaParagraph />, link: '/ai/summarize-text' },
    { title: 'Product Description', description: 'Generate engaging and persuasive product descriptions that highlight the features and benefits of your products. Enhance your e-commerce listings and attract more customers.', icon: <FaBox />, link: '/ai/product-description' },
    { title: 'Article Generator', description: 'Create informative and well-structured articles on any topic with our Article Generator. Perfect for content creators, journalists, and marketers needing high-quality content quickly.', icon: <FaNewspaper />, link: '/ai/article-generator' },
    { title: 'Product Name Generator', description: 'Discover creative and memorable product names that resonate with your target audience. Use our Product Name Generator to find the perfect name for your new product or brand.', icon: <FaCogs />, link: '/ai/product-name-generator' },
    { title: 'Testimonial Review', description: 'Generate authentic and impactful testimonial reviews that build trust and credibility. Ideal for enhancing your website or marketing materials with genuine customer feedback.', icon: <FaStar />, link: '/ai/testimonial-review' },
    { title: 'Problem Agitate Solution', description: 'Craft compelling problem-agitate-solution statements that capture attention and drive action. Perfect for persuasive writing, marketing copy, and sales pitches.', icon: <FaExclamationTriangle />, link: '/ai/problem-agitate-solution' },
    { title: 'Blog Section', description: 'Create well-organized and engaging blog sections for your website. Use our Blog Section tool to structure your content effectively and keep your readers interested.', icon: <FaStream />, link: '/ai/blog-section' },
    { title: 'Blog Post Ideas', description: 'Find inspiration for your next blog post with our Blog Post Ideas tool. Generate creative and relevant topics that will engage your audience and boost your content strategy.', icon: <FaLightbulb />, link: '/ai/blog-post-ideas' },
    { title: 'Blog Intros', description: 'Write captivating blog introductions that grab your readers attention from the start. Use our Blog Intros tool to craft openings that set the stage for compelling content.', icon: <FaPenNib />, link: '/ai/blog-intros' },
    { title: 'Blog Conclusion', description: 'Generate strong and memorable blog conclusions that leave a lasting impression. Summarize your key points and encourage reader engagement with our Blog Conclusion tool.', icon: <FaCheckCircle />, link: '/ai/blog-conclusion' },
    { title: 'Facebook Ads', description: 'Create eye-catching and effective Facebook ads that drive results. Use our Facebook Ads tool to generate compelling ad copy that attracts clicks and conversions.', icon: <FaFacebookF />, link: '/ai/facebook-ads' },
    { title: 'Instagram Hashtags', description: 'Enhance your Instagram posts with relevant and trending hashtags. Use our Instagram Hashtags tool to increase visibility and engagement on your social media content.', icon: <FaHashtag />, link: '/ai/instagram-hashtags' },
    { title: 'Social Media Post Tweets', description: 'Craft engaging and concise tweets for your Twitter account. Our Social Media Post Tweets tool helps you generate content that captures attention and sparks conversations.', icon: <FaTwitter />, link: '/ai/social-media-post-tweets' },
    { title: 'Youtube Video Description', description: 'Write informative and engaging YouTube video descriptions that improve visibility and attract viewers. Use our YouTube Video Description tool to enhance your video content.', icon: <FaVideo />, link: '/ai/youtube-video-description' },
    { title: 'Youtube Video Title', description: 'Generate catchy and SEO-friendly titles for your YouTube videos. Our YouTube Video Title tool helps you create titles that drive clicks and improve search rankings.', icon: <FaVideo />, link: '/ai/youtube-video-title' },
    { title: 'Youtube Video Tag', description: 'Optimize your YouTube videos with relevant tags to improve discoverability. Use our YouTube Video Tag tool to find and apply effective tags for better video performance.', icon: <FaTag />, link: '/ai/youtube-video-tag' },
    { title: 'Instagram Captions', description: 'Write captivating Instagram captions that resonate with your audience. Use our Instagram Captions tool to create engaging and shareable content for your posts.', icon: <FaInstagram />, link: '/ai/instagram-captions' },
    { title: 'Social Media Post Business', description: 'Develop professional and impactful social media posts for your business. Our Social Media Post Business tool helps you craft content that strengthens your brand and engages your audience.', icon: <FaBriefcase />, link: '/ai/social-media-post-business' },
    { title: 'Facebook Headlines', description: 'Generate attention-grabbing headlines for your Facebook posts. Use our Facebook Headlines tool to create headlines that boost engagement and drive traffic to your content.', icon: <FaFacebookF />, link: '/ai/facebook-headlines' },
    { title: 'Google Ads Headlines', description: 'Create impactful and attention-grabbing headlines for your Google Ads campaigns. Our Google Ads Headlines tool helps you craft headlines that drive clicks and maximize ad performance.', icon: <FaGoogle />, link: '/ai/google-ads-headlines' },
    { title: 'Google Ads Description', description: 'Generate compelling and concise descriptions for your Google Ads. Use our Google Ads Description tool to create descriptions that effectively communicate your message and encourage user action.', icon: <FaGoogle />, link: '/ai/google-ads-description' },
    { title: 'Paragraph Generator', description: 'Quickly produce well-structured and coherent paragraphs on any topic with our Paragraph Generator. Ideal for writers, bloggers, and content creators needing content in a flash.', icon: <FaParagraph />, link: '/ai/paragraph-generator' },
    { title: 'Pros & Cons', description: 'Generate balanced and insightful pros and cons lists for any topic or decision. Use our Pros & Cons tool to help evaluate options and make informed choices with clear advantages and disadvantages.', icon: <FaBalanceScale />, link: '/ai/pros-cons' },
    { title: 'Meta Description', description: 'Write effective and SEO-friendly meta descriptions that improve your search engine rankings and attract clicks. Use our Meta Description tool to create summaries that engage users and enhance your contents visibility.', icon: <FaSearch />, link: '/ai/meta-description' },
    { title: 'FAQ Generator', description: 'Create comprehensive and helpful FAQs for your website or product. Our FAQ Generator tool assists in crafting clear and relevant answers to common questions, improving user experience and reducing support queries.', icon: <FaQuestionCircle />, link: '/ai/faq-generator' },
    { title: 'Email Generator', description: 'Quickly draft professional and effective emails with our Email Generator. Whether for business, marketing, or personal use, this tool helps you create polished and impactful email content.', icon: <FaEnvelope />, link: '/ai/email-generator' },
    { title: 'Email Answer Generator', description: 'Generate thoughtful and appropriate responses to email inquiries with our Email Answer Generator. Perfect for handling customer support, business communications, and other email interactions efficiently.', icon: <FaEnvelope />, link: '/ai/email-answer-generator' },
    // Add more buttons as needed
  ];
  

  return (
    <div
      className="flex min-h-screen text-white"
      style={{
        backgroundColor: '#000000', // Base color black for the whole screen
        backgroundImage: `
          radial-gradient(at 20% 30%, rgba(0, 255, 255, 0.3) 0px, transparent 50%), /* Subtle Cyan Gradient */
          radial-gradient(at 80% 70%, rgba(0, 128, 128, 0.2) 0px, transparent 50%), /* Subtle Teal Gradient */
          radial-gradient(at 50% 50%, rgba(0, 0, 0, 0.95) 0px, transparent 50%)  /* Black Gradient for depth */
        `,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      {/* Sidebar */}
      <div className="hidden md:flex flex-none w-64 bg-[#1B1917]"> {/* Fixed sidebar width */}
        {/* Sidebar content goes here */}
      </div>
      
      {/* Main Content Area */}
      <div className="flex-1 flex flex-col">
        <header className="text-center mt-20 px-4"> {/* Adjusted margin-top */}
          <h1 className="text-2xl font-bold">MindWriter Ai</h1>
          <p className="text-sm mt-1"> {/* Reduced margin-top */}
            Text Generator & AiCopywriting Assistant
          </p>
        </header>

        {/* Filter Container */}
        <div className="mt-4 mx-4 p-4 rounded-lg border border-gray-500" style={{ maxHeight: '200px', overflowY: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <ul className="flex flex-wrap items-center justify-center gap-x-4 gap-y-2">
            {['All', 'Favorite', 'Youtube', 'Voiceover', 'Social media', 'Rss', 'Ecommerce', 'Development', 'Custom'].map((filter) => (
              <li key={filter}>
                <button
                  className="lqd-btn group flex items-center justify-center gap-1.5 font-medium hover:shadow-xl hover:shadow-black/5 bg-transparent text-white focus-visible:bg-white/10 rounded-full px-2.5 py-0.5 text-2xs leading-tight transition-colors hover:bg-white/5"
                  type="button"
                >
                  {filter}
                </button>
              </li>
            ))}
          </ul>
        </div>

        {/* Content Container */}
        <div className="flex-1 mt-4 mx-4 p-4 rounded-lg border border-gray-500" style={{ maxHeight: 'calc(100vh - 320px)', overflowY: 'auto' }}>
          {/* Grid of Buttons */}
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {buttonData.map((button, index) => (
              <Link to={button.link}
                key={index}
                className="flex flex-col items-start gap-2 p-4 border border-gray-500 text-white rounded-lg shadow-md hover:bg-[#16303f] w-full h-full"
                style={{ minHeight: '120px' }}
              >
                <div className="text-lg md:text-2xl">{button.icon}</div> {/* Icon */}
                <div className="flex flex-col text-left w-full">
                  <div className="font-semibold text-base md:text-xl truncate">{button.title}</div> {/* Title */}
                  <div className="text-sm md:text-base text-gray-400 break-words">{button.description}</div> {/* Description */}
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AllAi;
