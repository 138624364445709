import { defaults } from 'autoprefixer';
import React, { useState } from 'react';
import { FaEdit } from 'react-icons/fa';

function Contents() {
  const [title, setTitle] = useState('Untitled');
  const [isEditing, setIsEditing] = useState(false);
  const [newTitle, setNewTitle] = useState(title);
  const [description, setdescription] = useState('');
  const [keywords, setKeywords] = useState('');
  const [language, setLanguage] = useState('');
  const [maxLength, setMaxLength] = useState('');
  const [tone, setTone] = useState('');
  const [response, setResponse] = useState('');

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleTitleChange = (e) => {
    setNewTitle(e.target.value);
  };

  const handleTitleSubmit = (e) => {
    e.preventDefault();
    setTitle(newTitle);
    setIsEditing(false);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const prompt = `Write Google ads headline product name: ${description}. Description is ${keywords}. Audience is worldwide. Maximum ${maxLength} words. Creativity is 0.75 between 0 and 1. Language is ${language}. Generate 1 different google ads headlines. Tone of voice must be ${tone}`;

    try {
      const res = await fetch('https://mindhubai.xyz:4000/generate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ message: prompt }), // Send the prompt in the "message" field
      });

      const data = await res.json();

      if (data && data.response) {
        setResponse(data.response);
      } else {
        setResponse('No response from the AI.');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setResponse('Error fetching data.');
    }
  };

  return (
    <main
      className="flex-1 text-white p-4 border-l-2 border-[#1B1917] md:pl-64"
      style={{
        minHeight: '100vh',
        backgroundColor: '#000000',
        backgroundImage: `
          radial-gradient(circle at 20% 30%, rgba(0, 255, 255, 0.3) 0%, transparent 50%),
          radial-gradient(circle at 80% 70%, rgba(0, 0, 0, 0.95) 0%, transparent 50%),
          radial-gradient(circle at 50% 50%, rgba(128, 0, 128, 0.3) 0%, transparent 50%)
        `,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
      }}
    >
      <div className="flex-1 flex flex-col">
        <header className="text-center mt-20 px-4">
          <h1 className="text-2xl font-bold">Google Ads Headlines</h1>
          <p className="text-sm mt-1">
          Create impactful headlines for Google Ads campaigns
          </p>
        </header>

        {/* Container with two aligned sections */}
        <div className="container mx-auto mt-8 flex flex-col md:flex-row gap-6">
          {/* First Section */}
          <div className="flex-1 p-6 border border-gray-700 rounded-lg">
            <h2 className="text-xl font-semibold mb-4">Recent</h2>

            {/* Form for Article Title and Settings */}
            <form onSubmit={handleFormSubmit} className="space-y-4">
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-300 mb-1">Product Name</label>
                <textarea
                  type="text"
                  value={description}
                  onChange={(e) => setdescription(e.target.value)}
                  className="w-full p-2 border border-gray-600 bg-transparent text-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-cyan-500"
                  placeholder="Enter Name"
                />
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-300 mb-1">Description</label>
                <input
                  type="text"
                  value={keywords}
                  onChange={(e) => setKeywords(e.target.value)}
                  className="w-full p-2 border border-gray-600 bg-transparent text-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-cyan-500"
                  placeholder="Enter Description"
                />
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-300 mb-1">Language</label>
                <select
                  value={language}
                  onChange={(e) => setLanguage(e.target.value)}
                  className="w-full p-2 border border-gray-600 bg-transparent text-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-cyan-500"
                >
                  <option value="">Select language</option>
                  <option value="en">English</option>
                  <option value="es">Spanish</option>
                  <option value="fr">French</option>
                  {/* Add more language options as needed */}
                </select>
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-300 mb-1">Maximum Length (in words)</label>
                <input
                  type="number"
                  value={maxLength}
                  onChange={(e) => setMaxLength(e.target.value)}
                  className="w-full p-2 border border-gray-600 bg-transparent text-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-cyan-500"
                  placeholder="Enter maximum length"
                />
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-300 mb-1">Creativity/Tone of Voice</label>
                <textarea
                  value={tone}
                  onChange={(e) => setTone(e.target.value)}
                  className="w-full p-2 border border-gray-600 bg-transparent text-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-cyan-500"
                  rows="4"
                  placeholder="Describe the creativity or tone of voice"
                />
              </div>

              {/* Gradient Button */}
              <button
                type="submit"
                className="w-full py-2 px-4 rounded-lg text-white font-semibold bg-gradient-to-r from-cyan-500 to-purple-500 hover:from-cyan-600 hover:to-purple-600 transition duration-300"
              >
                Generate
              </button>
            </form>
          </div>

          {/* Second Section styled like a textarea */}
          <div className="flex-1 p-6 border border-gray-700 rounded-lg bg-transparent text-gray-300">
            <div className="flex items-center mb-4">
              {isEditing ? (
                <form onSubmit={handleTitleSubmit} className="flex items-center w-full">
                  <input
                    type="text"
                    value={newTitle}
                    onChange={handleTitleChange}
                    className="w-full p-2 border border-gray-600 bg-transparent text-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-cyan-500"
                    autoFocus
                  />
                  <button type="submit" className="ml-2 text-cyan-500">Save</button>
                </form>
              ) : (
                <>
                  <h2 className="text-xl font-semibold flex-1">{title}</h2>
                  <FaEdit 
                    className="ml-2 cursor-pointer text-gray-400 hover:text-gray-300"
                    onClick={handleEditClick}
                  />
                </>
              )}
            </div>
            <textarea
              value={response} // Bind the API response to the textarea
              className="w-full h-52 p-4 border border-gray-600 bg-transparent text-gray-300 resize-none rounded-md focus:outline-none focus:ring-2 focus:ring-cyan-500"
              readOnly // Make it read-only since it's displaying the response
            />
          </div>
        </div>
      </div>
    </main>
  );
}

export default Contents;
