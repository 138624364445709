import React, { useState } from 'react';
import { FaEdit } from 'react-icons/fa';

function Contents() {
  const [title, setTitle] = useState('Untitled');
  const [isEditing, setIsEditing] = useState(false);
  const [newTitle, setNewTitle] = useState(title);
  const [description, setDescription] = useState(''); // Google Ads product name
  const [keywords, setKeywords] = useState(''); // Description for the prompt
  const [language, setLanguage] = useState(''); // Selected style/language
  const [maxLength, setMaxLength] = useState(''); // Max words
  const [tone, setTone] = useState(''); // Tone of voice
  const [response, setResponse] = useState(''); // AI response
  const [images, setImages] = useState([]); // State to hold base64 images
  const [loading, setLoading] = useState(false); // Loader state

  const handleEditClick = () => {
    setIsEditing(true); // Enable editing mode for the title
  };

  const handleTitleChange = (e) => {
    setNewTitle(e.target.value); // Update new title as user types
  };

  const handleTitleSubmit = (e) => {
    e.preventDefault();
    setTitle(newTitle); // Update title with the new title
    setIsEditing(false); // Exit editing mode
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Show loader

    // Create the prompt based on user inputs
    const prompt = `Generate Image of ${description}, design ${keywords} with style of ${language} , Image Tone is ${tone}`;

    try {
      const res = await fetch('https://mindhubai.xyz:4000/generateimage', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ prompt }), // Send the prompt in the "prompt" field
      });

      const data = await res.json();

      console.log("API Response:", data); // Log the response for debugging

      if (data && data.images) { // Check for images directly
        if (Array.isArray(data.images) && data.images.length > 0) {
          setImages(data.images.slice(0, 4)); // Limit to 4 images
          setResponse(''); // Clear the previous response message
        } else {
          setImages([]); // Reset images if none are provided
          setResponse('No images generated.'); // Update the response message
        }
      } else {
        setResponse('No response from the AI.'); // Handle no response case
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setResponse('Error fetching data.'); // Handle fetch error
    } finally {
      setLoading(false); // Hide loader
    }
  };

  return (
    <main
      className="flex-1 text-white p-4 border-l-2 border-[#1B1917] md:pl-64"
      style={{
        minHeight: '100vh',
        backgroundColor: '#000000',
        backgroundImage: `
          radial-gradient(circle at 20% 30%, rgba(0, 255, 255, 0.3) 0%, transparent 50%),
          radial-gradient(circle at 80% 70%, rgba(0, 0, 0, 0.95) 0%, transparent 50%),
          radial-gradient(circle at 50% 50%, rgba(128, 0, 128, 0.3) 0%, transparent 50%)
        `,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
      }}
    >
      <div className="flex-1 flex flex-col">
        <header className="text-center mt-20 px-4">
          <h1 className="text-2xl font-bold">MindGen Image</h1>
          <p className="text-sm mt-1">MindGen: Crafting Descriptive Visuals</p>
        </header>

        {/* Container with two aligned sections */}
        <div className="container mx-auto mt-8 flex flex-col md:flex-row gap-6">
          {/* First Section */}
          <div className="flex-1 p-6 border border-gray-700 rounded-lg">
            <h2 className="text-xl font-semibold mb-4">Recent</h2>

            {/* Form for Article Title and Settings */}
            <form onSubmit={handleFormSubmit} className="space-y-4">
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-300 mb-1">Title</label>
                <input
                  type="text"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  className="w-full p-2 border border-gray-600 bg-transparent text-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-cyan-500"
                  placeholder="Enter Tittle Name"
                />
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-300 mb-1">Prompt</label>
                <textarea
                  type="text"
                  value={keywords}
                  onChange={(e) => setKeywords(e.target.value)}
                  className="w-full p-2 border border-gray-600 bg-transparent text-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-cyan-500"
                  placeholder="Enter Prompt"
                />
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-300 mb-1">Style</label>
                <select
                  value={language}
                  onChange={(e) => setLanguage(e.target.value)}
                  className="w-full p-2 border border-gray-600 bg-transparent text-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-cyan-500"
                >
                  <option value="">Select Style</option>
                  <option value="Anime">Anime</option>
                  <option value="Magepunk">Magepunk</option>
                  <option value="Futuristic">Futuristic</option>
                  <option value="Cyberpunk">Cyberpunk</option>
                  <option value="Steampunk">Steampunk</option>
                  <option value="Vintage">Vintage</option>
                  <option value="Minimalist">Minimalist</option>
                  <option value="Fantasy">Fantasy</option>
                  <option value="Noir">Noir</option>
                  <option value="Retro">Retro</option>
                  <option value="Abstract">Abstract</option>
                  <option value="Pop Art">Pop Art</option>
                  <option value="Surreal">Surreal</option>
                  <option value="Gothic">Gothic</option>
                  <option value="Neon">Neon</option>
                  <option value="Baroque">Baroque</option>
                  <option value="Rococo">Rococo</option>
                  <option value="Impressionist">Impressionist</option>
                  <option value="Cubist">Cubist</option>
                  <option value="Expressionist">Expressionist</option>
                  <option value="Art Deco">Art Deco</option>
                  <option value="Art Nouveau">Art Nouveau</option>
                  <option value="Bauhaus">Bauhaus</option>
                  <option value="Dada">Dada</option>
                  <option value="Grunge">Grunge</option>
                  <option value="Pixel Art">Pixel Art</option>
                  <option value="Comic Book">Comic Book</option>
                  <option value="Graffiti">Graffiti</option>
                  <option value="Film Noir">Film Noir</option>
                  <option value="Realism">Realism</option>
                  <option value="Photorealism">Photorealism</option>
                  <option value="Folk Art">Folk Art</option>
                  <option value="High Fantasy">High Fantasy</option>
                  <option value="Low Poly">Low Poly</option>
                  <option value="Watercolor">Watercolor</option>
                  <option value="Sketch">Sketch</option>
                  <option value="Ink Wash">Ink Wash</option>
                  <option value="Manga">Manga</option>
                  <option value="Sci-Fi">Sci-Fi</option>
                  <option value="Utopian">Utopian</option>
                  <option value="Dystopian">Dystopian</option>
                  <option value="Biomechanical">Biomechanical</option>
                  <option value="Surrealism">Surrealism</option>
                  <option value="Gothic Revival">Gothic Revival</option>
                  <option value="Industrial">Industrial</option>
                  <option value="Kawaii">Kawaii</option>
                  <option value="Fantasy Realism">Fantasy Realism</option>
                  <option value="Hyperrealism">Hyperrealism</option>
                  <option value="Lowbrow">Lowbrow</option>
                  <option value="Psychedelic">Psychedelic</option>
                </select>
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-300 mb-1">Tone</label>
                <select
                  value={tone}
                  onChange={(e) => setTone(e.target.value)}
                  className="w-full p-2 border border-gray-600 bg-transparent text-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-cyan-500"
                >
                  <option value="">Select Tone</option>
                  <option value="Formal">Formal</option>
                  <option value="Informal">Informal</option>
                  <option value="Playful">Playful</option>
                  <option value="Serious">Serious</option>
                  <option value="Friendly">Friendly</option>
                  <option value="Inspirational">Inspirational</option>
                  <option value="Humorous">Humorous</option>
                </select>
              </div>

             

              <div className="flex justify-center mb-4">
                <button
                  type="submit"
                  className="w-full bg-cyan-600 hover:bg-cyan-700 text-white font-bold py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-cyan-500"
                >
                  Generate
                </button>
              </div>
            </form>
          </div>

          {/* Second Section for displaying images */}
          <div className="flex-1 p-6 border border-gray-700 rounded-lg">
            <h2 className="text-xl font-semibold mb-4">Results</h2>
            {loading ? (
              <div className="loader"></div> // Show loader when loading
            ) : (
              <div className="grid grid-cols-2 gap-4">
                {images.length > 0 ? (
                  images.map((img, index) => (
                    <div key={index} className="overflow-hidden rounded-lg shadow-md">
                      <img src={img} alt={`Generated ${index}`} className="w-full h-auto" />
                    </div>
                  ))
                ) : (
                  <p className="text-center text-gray-400">No images generated yet.</p>
                )}
              </div>
            )}
            {response && <p className="text-center text-red-500 mt-4">{response}</p>}
          </div>
        </div>
      </div>

      <style>
        {`
          /* Loader CSS */
          .loader {
            width: 50px;
            --b: 8px;
            aspect-ratio: 1;
            border-radius: 50%;
            background: #514b82;
            -webkit-mask:
              repeating-conic-gradient(#0000 0deg,#000 1deg 70deg,#0000 71deg 90deg),
              radial-gradient(farthest-side,#0000 calc(100% - var(--b) - 1px),#000 calc(100% - var(--b)));
            -webkit-mask-composite: destination-in;
                    mask-composite: intersect;
            animation: l5 1s infinite;
          }
          @keyframes l5 {to{transform: rotate(.5turn)}}
        `}
      </style>
    </main>
  );
}

export default Contents;
